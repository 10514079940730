<template>
  <div>
    <table-pagination :pagination="pagination" :filter="filter" @load="loadData"></table-pagination>
    <b-table
      :data="rows"
      bordered
      narrowed
      :loading="loadingTable"
    >
      <template #empty>
        <p class="m-5 p-5 has-text-danger has-text-centered" v-if="!loadingTable">
          No hay registros para mostrar
        </p>
        <div v-else>
          <b-skeleton animated></b-skeleton>
          <b-skeleton animated></b-skeleton>
          <b-skeleton animated></b-skeleton>
          <b-skeleton animated></b-skeleton>
          <b-skeleton animated></b-skeleton>
        </div>
      </template>
      <b-table-column 
        label="N°"
        header-class="has-background-grey-dark has-text-white-bis" v-slot="props">
        {{ props.index + 1 }}
      </b-table-column>
      <b-table-column
        label="Informe"
        v-slot="{ row }"
        header-class="has-background-grey-dark has-text-white-bis"
      >
        <router-link :to="`/form/1623469803663/${row.dashboards[0].id}`">
          {{ row.nombre }}
        </router-link>
      </b-table-column>
      <b-table-column
        label="Descripción"
        v-slot="{ row }"
        header-class="has-background-grey-dark has-text-white-bis"
      >
        {{ row.descripcion }}
      </b-table-column>
      <b-table-column
        label="Favorito"
        v-slot="{ row }"
        header-class="has-background-grey-dark has-text-white-bis"
        centered
      >
        <span class="is-clickable" @click="handleChangeFavorite(row)">
          <b-icon 
            icon="star" 
            :type="row.attrs.favorite ? `is-warning` : null"
            :pack="!row.attrs.favorite ? `far` : `fas`" />
        </span>
      </b-table-column>
      <b-table-column
        label="Creado"
        v-slot="{ row }"
        header-class="has-background-grey-dark has-text-white-bis"
      >
        {{ row.created_at }}
      </b-table-column>
      <b-table-column
        label="Actualizado"
        v-slot="{ row }"
        header-class="has-background-grey-dark has-text-white-bis"
      >
        {{ row.updated_at }}
      </b-table-column>
      <b-table-column
        centered
        width="40"
        header-class="has-background-grey-dark has-text-white-bis"
        v-slot="{row}"
      >
        <b-dropdown position="is-bottom-left">
          <template #trigger>
            <b-button
              icon-left="ellipsis-v"
              type="is-primary is-light"
              size="is-small"
            />
          </template>
          <b-dropdown-item aria-role="listitem" @click="edit(row)"
            ><b-icon icon="edit"></b-icon> Editar</b-dropdown-item
          >
          <b-dropdown-item aria-role="listitem" @click="destroy(row)"
            ><b-icon icon="trash"></b-icon> Eliminar</b-dropdown-item
          >
        </b-dropdown>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
export default {
  props:{
    loadingTable:{
      type: Boolean,
      default: () => false,
    }
  },
  computed:{
    name_store(){
      return `form_1617206897966`
    },
    getters(){
      return key => this.$store.getters[`${this.name_store}/${key}`]
    },
    rows(){
      return this.getters(`rows`)
    },
    pagination(){
      return this.getters(`pagination`)
    },
    filter(){
      return this.getters(`filter`)
    },
  },
  methods:{
    loadData(url, params){
      this.$emit("onSubmit", url, params)
    },
    edit(row){
      this.$router.push({name: `informe-nuevo`, query: {id: row.id}})
    },
    async handleChangeFavorite(row){
      await this.$store.dispatch(`${this.name_store}/save`, {id: row.id, favorite: row.attrs.favorite ? false : true })
      this.loadData()
    },
    async destroy(row){
      if (confirm(`Realmente desea eliminar el informe "${row.nombre}"?`)) {
        const e = await this.$store.dispatch(`${this.name_store}/destroy`, row.id)
        if(e){
          this.loadData()
        }
      }
    }
  }
};
</script>