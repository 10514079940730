<template>
  <form-observer ref="observer" @submit.prevent="onSubmit">
    <div class="columns">
      <div class="column">
        <input-base
          rules=""
          label="Informe"
          placeholder="Informe"
          v-model="form.nombre"
          size="is-small"
        />
      </div>
      <div class="column">
        <input-base
          label="Descripción"
          placeholder="Descripción"
          v-model="form.descripcion"
          maxlength="200"
          size="is-small"
        />
      </div>
      <div class="column">
        <b-field label="Favorito">
          <b-switch v-model="form.favorite"
              true-value="true"
              false-value="false">
          </b-switch>
        </b-field>
      </div>
    </div>
    <div class="columns">
      <div class="column is-flex is-align-items-center">
        <button-search />
        <button-clear
          v-model="form" 
          @clear="() => $refs.observer.reset()" />
      </div>
    </div>
  </form-observer>
</template>
<script>
import {getQueryUrl} from "@/utils/helpers"
export default {
  data() {
    return {
      form: {},
    };
  },
  computed:{
    name_store(){
      return `form_1617206897966`
    },
  },
  created() {
    const e = getQueryUrl()
    if(e) this.$set(this, 'form', e)
    this.onSubmit()
  },
  methods: {
    async onSubmit(){
      if(this.$refs.observer){
        const isValid = await this.$refs.observer.validate();
        if (!isValid) return;
      }
      this.$emit("onSubmit", null, this.form)
    },
  }
};
</script>