<template>
  <div>
    <title-bar :items="[{ title }]">
      <template #buttonsleft>
        <button-header 
          tag="router-link"
          :to="{ name: `informe-nuevo` }"
          icon-left="plus">
          Nuevo
        </button-header>
        <button-header
          icon-left="filter"
          @click="isOpen = !isOpen"
          aria-controls="contentIdForA11y2"
        >
          Filtrar
        </button-header>        
      </template>
    </title-bar>
    <TableComponent />
  </div>
</template>
<script>
import TableComponent from "../components/index/TableComponent";
export default {
  components: {
    TableComponent,
  },
  computed:{
    name_store(){
      return `form_1617206897966`
    },
    getters(){
      return key => this.$store.getters[`${this.name_store}/${key}`]
    },
    isOpen:{
      get(){
        return this.getters(`isOpenFilter`)
      },
      set(e){
        this.$store.commit(`${this.name_store}/SET_IS_OPEN_FILTER`, e)
      }
    },
    title(){
      return this.$route.meta.title || "";
    }
  }
};
</script>