var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table-pagination',{attrs:{"pagination":_vm.pagination,"filter":_vm.filter},on:{"load":_vm.loadData}}),_c('b-table',{attrs:{"data":_vm.rows,"bordered":"","narrowed":"","loading":_vm.loadingTable},scopedSlots:_vm._u([{key:"empty",fn:function(){return [(!_vm.loadingTable)?_c('p',{staticClass:"m-5 p-5 has-text-danger has-text-centered"},[_vm._v(" No hay registros para mostrar ")]):_c('div',[_c('b-skeleton',{attrs:{"animated":""}}),_c('b-skeleton',{attrs:{"animated":""}}),_c('b-skeleton',{attrs:{"animated":""}}),_c('b-skeleton',{attrs:{"animated":""}}),_c('b-skeleton',{attrs:{"animated":""}})],1)]},proxy:true}])},[_c('b-table-column',{attrs:{"label":"N°","header-class":"has-background-grey-dark has-text-white-bis"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.index + 1)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Informe","header-class":"has-background-grey-dark has-text-white-bis"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{attrs:{"to":("/form/1623469803663/" + (row.dashboards[0].id))}},[_vm._v(" "+_vm._s(row.nombre)+" ")])]}}])}),_c('b-table-column',{attrs:{"label":"Descripción","header-class":"has-background-grey-dark has-text-white-bis"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.descripcion)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Favorito","header-class":"has-background-grey-dark has-text-white-bis","centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"is-clickable",on:{"click":function($event){return _vm.handleChangeFavorite(row)}}},[_c('b-icon',{attrs:{"icon":"star","type":row.attrs.favorite ? "is-warning" : null,"pack":!row.attrs.favorite ? "far" : "fas"}})],1)]}}])}),_c('b-table-column',{attrs:{"label":"Creado","header-class":"has-background-grey-dark has-text-white-bis"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.created_at)+" ")]}}])}),_c('b-table-column',{attrs:{"label":"Actualizado","header-class":"has-background-grey-dark has-text-white-bis"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.updated_at)+" ")]}}])}),_c('b-table-column',{attrs:{"centered":"","width":"40","header-class":"has-background-grey-dark has-text-white-bis"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-dropdown',{attrs:{"position":"is-bottom-left"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('b-button',{attrs:{"icon-left":"ellipsis-v","type":"is-primary is-light","size":"is-small"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.edit(row)}}},[_c('b-icon',{attrs:{"icon":"edit"}}),_vm._v(" Editar")],1),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.destroy(row)}}},[_c('b-icon',{attrs:{"icon":"trash"}}),_vm._v(" Eliminar")],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }